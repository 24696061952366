import React, { Component } from 'react';

class Loading extends Component {
    render() {
        return (
            <div>
                <p>Loading...</p>
            </div>
        );
    }
}

export default Loading;